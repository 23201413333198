.social-med-icons.instagram {
  object-position: -2px 0;
}

.social-med-icons.facebook {
  object-position: -36px 0;
}

.social-med-icons.twitter {
  object-position: -69px 0;
}

.social-med-icons.youtube {
  object-position: -102px 0;
}
